<template>
  <div v-frag>
    <Alert/>
    <v-dialog
      v-model='dialog'
      max-width='1000px'
      scrollable
      transition='dialog-bottom-transition'
    >
      <v-form ref='form'>
        <v-card>
          <v-card-title>
            <span class='headline'>{{ formTitle }}</span>
          </v-card-title>
          <v-card-text class='py-2 px-4'>
            <v-container>
              <v-row class='mb-n8' md='4' sm='6'>
                <v-col>
                  <p class='font-weight-bold'>Member Company Name</p>
                  <v-text-field
                    v-model='editedItem.name'
                    dense
                    outlined
                    placeholder='Company name'
                  ></v-text-field>
                </v-col>
                <v-col>
                  <p class='font-weight-bold'>Company URL</p>
                  <v-text-field
                    v-model='editedItem.website'
                    dense
                    outlined
                    placeholder='www.mycompany.com'
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class='mb-n8' md='4' sm='6'>
                <v-col v-if='editedItem.adminName.length'>
                  <p class='font-weight-bold'>Admin Name</p>
                  <v-text-field
                    v-for='item in editedItem.adminName'
                    :key='item.user_name'
                    :value='item.user_name'
                    background-color='#f9f9fb'
                    dense
                    outlined
                    placeholder='Employee name'
                    readonly
                    hide-details
                    class='mb-2'
                  ></v-text-field>
                </v-col>
                <v-col>
                  <p class='font-weight-bold'>Enable/Disable Modules</p>
                  <v-row class=' d-flex flex-row align-center justify-start ml-4'>
                    <v-checkbox
                      v-model='editedItem.modules'
                      :rules='rules'
                      class='mr-4 pb-4 d-inline-block'
                      color='primary'
                      hide-details
                      label='Voting'
                      value='voting'
                    ></v-checkbox>
                    <v-checkbox
                      v-model='editedItem.modules'
                      :rules='rules'
                      class='mr-4 pb-4 d-inline-block'
                      color='primary'
                      hide-details
                      label='Volunteering Opportunities'
                      value='volunteering'
                    ></v-checkbox>
                    <v-checkbox
                      v-model='editedItem.modules'
                      :rules='rules'
                      class='mr-4 pb-4 d-inline-block'
                      color='primary'
                      hide-details
                      label='Donations'
                      value='donations'
                    ></v-checkbox>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class='mt-3'>
                  <p class='font-weight-bold'>Notification</p>
                  <v-col class="mt-0">
                    <v-checkbox
                      v-model='editedItem.missed_voting_notification'
                      :rules='rules'
                      class='mr-4 mt-n3 d-inline-flex'
                      color='primary'
                      hide-details
                      label='Missed voting notification'
                    ></v-checkbox>
                  </v-col>
                  <v-col class="mt-0">
                    <v-checkbox
                      v-model='editedItem.disabled_email_notification_ba'
                      :rules='rules'
                      class='mr-4 mt-n3 d-inline-flex'
                      color='primary'
                      hide-details
                      label='Opt-Out of Scheduled MCBA Emails'
                    ></v-checkbox>
                  </v-col>
                  <v-col class="mt-0">
                    <v-checkbox
                      v-model='editedItem.disabled_sms_notification_ba'
                      :rules='rules'
                      class='mr-4 pb-4 mt-n3 d-inline-flex'
                      color='primary'
                      hide-details
                      label='Opt-out of Scheduled MCBA SMS'
                    ></v-checkbox>
                  </v-col>
                </v-col>
                <v-col class='mt-3'>
                  <p class='font-weight-bold'>Archive Status</p>
                  <v-col class="mt-0">
                    <v-checkbox
                      v-model='editedItem.archived'
                      class='mr-4 mt-n3 d-inline-block'
                      color='primary'
                      hide-details
                      label='Is Archived'
                    ></v-checkbox>
                  </v-col>
                </v-col>
              </v-row>
              <v-row>
                <v-col class='mb-3'>
                  <p class='font-weight-bold'>Voter Guide</p>
                  <v-checkbox
                    v-model='editedItem.is_hidden_voter_guide'
                    class='mr-4 mt-n3 d-inline-flex'
                    color='primary'
                    hide-details
                    label='Hide Voter Guide'
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row md='4' sm='6'>
                <v-col md='4' sm='6'>
                  <p class='font-weight-bold my-0'>Import Employees Data</p>
                  <p class='font-weight-light my-0 red--text'>Please do not upload a file with filters.</p>
                  <p class='font-weight-light my-0'>Xlsx file*</p>
                </v-col>
              </v-row>
              <v-row class='px-4' md='4' sm='6'>
                <v-file-input
                  chips
                  dense
                  outlined
                  placeholder='Add a file or drop it here'
                  prepend-icon=''
                  prepend-inner-icon='mdi-paperclip'
                  show-size
                  truncate-length='50'
                  @change='selectFile'
                ></v-file-input>
              </v-row>
              <v-row class='d-flex justify-end align-center'>
                <v-btn
                  class='text-capitalize'
                  outlined
                  text
                  @click='download'
                >
                  Download Xlsx Template
                  <v-icon class='ml-4' left>mdi-download</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class='d-flex direction-row justify-center'>
            <v-btn
              class='px-14 ma-4 font-weight-bold'
              color='primary'
              large
              outlined
              @click='close'
            >
              Cancel
            </v-btn>
            <v-btn
              :disabled='!editedItem.modules.length'
              :loading='loadingSave'
              class='px-14 ma-4 font-weight-bold white--text'
              color='primary'
              large
              @click='save'
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model='dialogSuspend'
      max-width='500px'
    >
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-5'>
          <v-icon
            class='mr-4 white--text'
            color='warning'
            large
            left
          >
            mdi-alert
          </v-icon>
          Suspend Member
        </v-card-title>
        <v-card-subtitle>
          <b>You are about to suspend the following member:</b>
        </v-card-subtitle>
        <p class='ml-8 py-4 font-weight-bold secondary--text'>
          {{ editedItem.name }}
        </p>
        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold text-capitalize'
            color='primary'
            large
            outlined
            @click='closeSuspend'
          >
            Cancel
          </v-btn>
          <v-btn
            class='px-14 mx-4 font-weight-bold text-capitalize white--text'
            color='warning'
            large
            @click='suspendMemberConfirm'
          >
            Suspend
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tabs
      v-model='selectedTab'
      align-with-title
      class='tabs pt-1'
    >
      <v-tab
        v-for='tab in tabs'
        :key='tab.id'
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item v-if='selectedTab === 0' :value='0'>
        <v-data-table
          v-model='selected'
          :headers='allMembersTableHeaders'
          :items='members'
          :options.sync='membersTableMeta'
          :server-items-length='allMembersNumber'
          :items-per-page='15'
          :loading='loading'
          checkboxColor='primary'
          class='elevation-1 pa-4'
          item-key='id'
          loading-text='Loading members... Please wait'
          show-select
          @update:options='getMembers'
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title class='font-weight-bold'>{{ sections.member_companies || 'Members title' }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model='allMembersSearch'
                :loading='loading'
                :readonly='loading'
                class='mr-4'
                dense
                hide-details
                label='Search'
                outlined
                prepend-inner-icon='mdi-magnify'
                single-line
              >
              </v-text-field>
              <v-btn
                class='text-capitalize font-weight-bold py-5 px-10'
                color='primary'
                outlined
                @click='openAddMember'
              >
                <v-icon left> mdi-plus</v-icon>
                Add New
              </v-btn>
            </v-toolbar>
            <!-- create alert -->
            <v-alert
              v-model='showCreateAlert'
              class='rounded-xl'
              color='success'
              dark
              dense
              dismissible
              icon='mdi-check-circle-outline'
              prominent
              transition='scale-transition'
              width='100%'
            >
              <h3 class='mt-2'>A new member has been created successfully!</h3>

              <p>The task has been completed without issues.</p>
            </v-alert>
            <!-- suspend alert -->
            <v-alert
              v-model='showSuspendAlert'
              class='rounded-xl'
              color='warning'
              dark
              dense
              dismissible
              icon='mdi-information-outline'
              prominent
              transition='scale-transition'
              width='100%'
            >
              <h3 class='mt-2'>Member Suspended</h3>
              <p>
                You can always incorporate them back going to
                <b>"Suspended Members"</b> at the bottom of this page
              </p>
            </v-alert>
          </template>
          <template #item.adminName={item}>
            <v-btn
              v-if='!item.adminName.length'
              class='primary--text text-capitalize'
              outlined
              small
              @click='goToAdmin(item.id)'
            >
              Select Admin
            </v-btn>
            <div v-else>
              <p
                v-for='(item, idx) in item.adminName'
                :key='idx'
                class='mb-1'
              >
                {{ item.user_name }}
              </p>
            </div>
          </template>
          <template #item.lustUploadFile={item}>
            <span v-text='item.lustUploadFile ? item.lustUploadFile : "—"'></span>
          </template>
          <template #item.missed_voting_notification={item}>
            {{ item.missed_voting_notification ? 'ON' : 'OFF' }}
          </template>
          <template #item.disabled_email_notification_ba={item}>
            {{ item.disabled_email_notification_ba ? 'OFF' : 'ON' }}
          </template>
          <template #item.disabled_sms_notification_ba={item}>
            {{ item.disabled_sms_notification_ba ? 'OFF' : 'ON' }}
          </template>
          <template #item.is_hidden_voter_guide={item}>
            {{ !!item.is_hidden_voter_guide ? 'ON' : 'OFF' }}
          </template>
          <template #item.modules={item}>
            <v-chip
              v-if='item.editedModules.donations'
              class='white--text rounded-sm mr-1 px-2 font-weight-bold'
              color='secondary'
            >
              Donations
            </v-chip>
            <v-chip
              v-if='item.editedModules.voting'
              class='white--text rounded-sm mr-1 px-2 font-weight-bold'
              color='secondary'
            >
              Voting
            </v-chip>
            <v-chip
              v-if='item.editedModules.volunteering'
              class='white--text rounded-sm mr-1 px-2 font-weight-bold'
              color='secondary'
            >
              Volunteering
            </v-chip>
          </template>
          <template #item.actions={item}>
            <v-icon
              class='mr-2 my-1 pa-2 primary white--text rounded-sm'
              dense
              @click='editItem(item)'
            >
              mdi-pencil
            </v-icon>
            <v-icon
              class='mr-2 my-1 pa-2 warning white--text rounded-sm'
              dense
              @click='suspendMember(item)'
            >
              mdi-timer-off
            </v-icon>
          </template>
          <template #no-data>
            <span>There are no active members at the moment.</span>
          </template>
        </v-data-table>
      </v-tab-item>      
      <v-tab-item v-else-if='selectedTab === 1' :value='1'>
        <v-data-table
          v-model='suspendedSelected'
          :headers='suspendedTableHeaders'
          :items='suspendedMembers'
          :options.sync='membersTableMeta'
          :server-items-length='allMembersNumber'
          :items-per-page='15'
          :loading='loadingSuspended'
          checkboxColor='primary'
          class='elevation-1 pa-4'
          item-key='id'
          loading-text='Loading... Please wait'
          show-select
          @update:options='getSuspendedMembers'
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title class='font-weight-bold'>Suspended Member Companies</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model='allSuspendedSearch'
                :loading='loadingSuspended'
                :readonly='loadingSuspended'
                class='mr-4'
                dense
                hide-details
                label='Search'
                outlined
                prepend-inner-icon='mdi-magnify'
                single-line
              >
              </v-text-field>
              <v-dialog
                v-model='dialogDelete'
                max-width='500px'
              >
                <v-card class='d-flex flex-column align-start pa-2'>
                  <v-card-title class='text-center mb-5'>
                    <v-icon
                      class='mr-4 white--text'
                      color='error'
                      large
                      left
                    >
                      mdi-alert
                    </v-icon>
                    Delete Member
                  </v-card-title>
                  <v-card-subtitle>
                    <b v-if='singleDelete'>You are about to delete the following member:</b>
                    <b v-if='!singleDelete'>You are about to delete the following members:</b>
                  </v-card-subtitle>
                  <p
                    v-if='singleDelete'
                    class='ml-8 py-4 font-weight-bold secondary--text'
                  >
                    {{ editedItem.name }}
                  </p>
                  <div v-if='!singleDelete'>
                    <p
                      v-for='member in suspendedSelected'
                      :key='member.name'
                      class='ml-8 font-weight-bold secondary--text'
                    >
                      {{ member.name }}
                    </p>
                  </div>
                  <v-card-actions class='align-self-center'>
                    <v-spacer></v-spacer>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      outlined
                      @click='closeDelete'
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      @click='openDeleteConfirmation'
                    >
                      Confirm
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model='dialogDeleteConfirmation'
                max-width='550px'
              >
                <v-card class='d-flex flex-column align-start pa-2'>
                  <v-card-title class='text-center mb-5'>
                    <v-icon
                      class='mr-4 white--text'
                      color='error'
                      large
                      left
                    >
                      mdi-alert
                    </v-icon>
                    Confirm Delete Member
                  </v-card-title>
                  <v-card-subtitle>
                    <b v-if='singleDelete'>
                      Are you sure you want to delete this member permanently?
                      <span class='error--text'>There is no way to undo this action.</span>
                    </b>
                    <b v-if='!singleDelete'>
                      Are you sure you want to delete these members permanently?
                      <span class='error--text'>There is no way to undo this action.</span>
                    </b>
                  </v-card-subtitle>
                  <p
                    v-if='singleDelete'
                    class='ml-8 py-4 font-weight-bold secondary--text'
                  >
                    {{ editedItem.name }} will be deleted permanently.
                  </p>
                  <div v-if='!singleDelete'>
                    <p
                      v-for='member in suspendedSelected'
                      :key='member.name'
                      class='ml-8 font-weight-bold secondary--text'
                    >
                      {{ member.name }}
                    </p>
                  </div>
                  <v-card-actions class='align-self-center'>
                    <v-spacer></v-spacer>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      outlined
                      @click='closeDeleteConfirmation'
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='error'
                      large
                      @click='deleteItemConfirm'
                    >
                      Yes, Delete Now
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model='dialogReincorporate'
                max-width='500px'
              >
                <v-card class='d-flex flex-column align-start pa-2'>
                  <v-card-title class='text-center mb-5'>
                    <v-icon
                      class='mr-4 white--text'
                      color='success'
                      large
                      left
                    >
                      mdi-alert
                    </v-icon>
                    Reincorporate Member
                  </v-card-title>
                  <v-card-subtitle>
                    <b v-if='singleReincorporate'>You are about to reincorporate the following member:</b>
                    <b v-if='!singleReincorporate'>You are about to reincorporate the following members:</b>
                  </v-card-subtitle>
                  <p
                    v-if='singleReincorporate'
                    class='ml-8 py-4 font-weight-bold secondary--text'
                  >
                    {{ editedItem.name }}
                  </p>
                  <div v-if='!singleReincorporate'>
                    <p
                      v-for='member in suspendedSelected'
                      :key='member.name'
                      class='ml-8 font-weight-bold secondary--text'
                    >
                      {{ member.name }}
                    </p>
                  </div>
                  <v-card-actions class='align-self-center'>
                    <v-spacer></v-spacer>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      outlined
                      @click='closeReincorporate'
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      @click='reincorporateMemberConfirm'
                    >
                      Confirm
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-row
              v-if='suspendedSelected.length > 0'
              class='d-flex justify-start align-center ml-4 my-4'
            >
              <v-btn
                class='error--text mr-4'
                medium
                outlined
                @click='onDeleteBtnClick'
              >
                <v-icon left>mdi-close-thick</v-icon>
                Delete
              </v-btn>
              <v-btn
                class='success--text'
                medium
                outlined
                @click='onReincorporateBtnClick'
              >
                <v-icon left>mdi-undo</v-icon>
                Reincorporate
              </v-btn>
            </v-row>
            <!-- delete alert -->
            <v-alert
              v-model='showDeleteAlert'
              class='rounded-xl'
              color='grey'
              dark
              dense
              dismissible
              icon='mdi-information-outline'
              prominent
              transition='scale-transition'
              width='100%'
            >
              <h3
                v-if='singleDelete'
                class='mt-2'
              >Member Deleted
              </h3>
              <p v-if='singleDelete'>Selected member was successfully deleted.</p>

              <h3
                v-if='!singleDelete'
                class='mt-2'
              >Members Deleted
              </h3>
              <p v-if='!singleDelete'>Selected members were successfully deleted.</p>
            </v-alert>

            <!-- reincorporate alert -->
            <v-alert
              v-model='showReincorporateAlert'
              class='rounded-xl'
              color='success'
              dark
              dense
              dismissible
              icon='mdi-information-outline'
              prominent
              transition='scale-transition'
              width='100%'
            >
              <h3
                v-if='singleReincorporate'
                class='mt-2'
              >Member Reincorporated
              </h3>
              <p v-if='singleReincorporate'>Selected member was successfully reincorporated.</p>

              <h3
                v-if='!singleReincorporate'
                class='mt-2'
              >Members Reincorporated
              </h3>
              <p v-if='!singleReincorporate'>Selected member were successfully reincorporated.</p>
            </v-alert>
          </template>
          <template #item.adminName={item}>
            <v-btn
              v-if='!item.adminName.length'
              class='primary--text text-capitalize'
              outlined
              small
              @click='goToAdmin(item.id)'
            >
              Select Admin
            </v-btn>
            <div v-else>
              <p
                v-for='(item, idx) in item.adminName'
                :key='idx'
                class='mb-1'
              >
                {{ item.user_name }}
              </p>
            </div>
          </template>
          <template #item.lustUploadFile={item}>
            <span v-text='item.lustUploadFile ? item.lustUploadFile : "—"'></span>
          </template>
          <template #item.modules={item}>
            <v-chip
              v-if='item.editedModules.donations'
              class='white--text rounded-sm mr-1 px-2 font-weight-bold'
              color='secondary'
            >
              Donations
            </v-chip>
            <v-chip
              v-if='item.editedModules.voting'
              class='white--text rounded-sm mr-1 px-2 font-weight-bold'
              color='secondary'
            >
              Voting
            </v-chip>
            <v-chip
              v-if='item.editedModules.volunteering'
              class='white--text rounded-sm mr-1 px-2 font-weight-bold'
              color='secondary'
            >
              Volunteering
            </v-chip>
          </template>
          <template #item.actions={item}>
            <v-icon
              class='mr-2 my-1 pa-2 error white--text rounded-sm'
              dense
              @click='deleteItem(item)'
            >
              mdi-close-thick
            </v-icon>
            <v-icon
              class='mr-2 my-1 pa-2 success white--text rounded-sm'
              dense
              @click='reincorporateMember(item)'
            >
              mdi-undo
            </v-icon>
          </template>
          <template #no-data>
            <span>There are no suspended members at the moment.</span>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item v-if='selectedTab === 2' :value='2'>
        <v-data-table
          v-model='selected'
          :headers='allMembersTableHeaders'
          :items='members'
          :options.sync='membersTableMeta'
          :server-items-length='allMembersNumber'
          :items-per-page='15'
          :loading='loading'
          checkboxColor='primary'
          class='elevation-1 pa-4'
          item-key='id'
          loading-text='Loading members... Please wait'
          show-select
          @update:options='getArchivedMembers'
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title class='font-weight-bold'>Archived Member Companies</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model='allArchivedSearch'
                :loading='loading'
                :readonly='loading'
                class='mr-4'
                dense
                hide-details
                label='Search'
                outlined
                prepend-inner-icon='mdi-magnify'
                single-line
              >
              </v-text-field>
            </v-toolbar>
            <v-row
              v-if='selected.length'
              class='d-flex justify-start align-center px-10 mt-4'
            >
              <p class='mt-4 pr-4'>Modify Modules:</p>
              <v-checkbox
                v-model='selectedModules'
                class='mr-4'
                color='primary'
                label='Voting'
                value='voting'
              ></v-checkbox>
              <v-checkbox
                v-model='selectedModules'
                class='mr-4'
                color='primary'
                label='Volunteering'
                value='volunteering'
              ></v-checkbox>
              <v-checkbox
                v-model='selectedModules'
                class='mr-4'
                color='primary'
                label='Donations'
                value='donations'
              ></v-checkbox>
              <v-spacer></v-spacer>
              <v-btn
                :disabled='!selectedModules.length'
                class='font-weight-bold white--text text-capitalize'
                depressed
                color='primary'
                large
                @click='updateModules'
              >
                Apply Changes
              </v-btn>
            </v-row>
            <v-alert
              v-model='showSuccessAlert'
              class='rounded-xl'
              color='success'
              dark
              dense
              dismissible
              icon='mdi-check-circle-outline'
              prominent
              transition='scale-transition'
              width='100%'
            >
              <h3 class='mt-2'>Member modified successfully!</h3>
              <p>The task has been completed without issues.</p>
            </v-alert>

            <!-- create alert -->
            <v-alert
              v-model='showCreateAlert'
              class='rounded-xl'
              color='success'
              dark
              dense
              dismissible
              icon='mdi-check-circle-outline'
              prominent
              transition='scale-transition'
              width='100%'
            >
              <h3 class='mt-2'>A new member has been created successfully!</h3>

              <p>The task has been completed without issues.</p>
            </v-alert>
            <!-- suspend alert -->
            <v-alert
              v-model='showSuspendAlert'
              class='rounded-xl'
              color='warning'
              dark
              dense
              dismissible
              icon='mdi-information-outline'
              prominent
              transition='scale-transition'
              width='100%'
            >
              <h3 class='mt-2'>Member Suspended</h3>
              <p>
                You can always incorporate them back going to
                <b>"Suspended Members"</b> at the bottom of this page
              </p>
            </v-alert>
          </template>
          <template #item.adminName={item}>
            <v-btn
              v-if='!item.adminName.length'
              class='primary--text text-capitalize'
              outlined
              small
              @click='goToAdmin(item.id)'
            >
              Select Admin
            </v-btn>
            <div v-else>
              <p
                v-for='(item, idx) in item.adminName'
                :key='idx'
                class='mb-1'
              >
                {{ item.user_name }}
              </p>
            </div>
          </template>
          <template #item.lustUploadFile={item}>
            <span v-text='item.lustUploadFile ? item.lustUploadFile : "—"'></span>
          </template>
          <template #item.missed_voting_notification={item}>
            {{ item.missed_voting_notification ? 'ON' : 'OFF' }}
          </template>
          <template #item.disabled_email_notification_ba={item}>
            {{ item.disabled_email_notification_ba ? 'OFF' : 'ON' }}
          </template>
          <template #item.disabled_sms_notification_ba={item}>
            {{ item.disabled_sms_notification_ba ? 'OFF' : 'ON' }}
          </template>
          <template #item.is_hidden_voter_guide={item}>
            {{ !!item.is_hidden_voter_guide ? 'ON' : 'OFF' }}
          </template>
          <template #item.modules={item}>
            <v-chip
              v-if='item.editedModules.donations'
              class='white--text rounded-sm mr-1 px-2 font-weight-bold'
              color='secondary'
            >
              Donations
            </v-chip>
            <v-chip
              v-if='item.editedModules.voting'
              class='white--text rounded-sm mr-1 px-2 font-weight-bold'
              color='secondary'
            >
              Voting
            </v-chip>
            <v-chip
              v-if='item.editedModules.volunteering'
              class='white--text rounded-sm mr-1 px-2 font-weight-bold'
              color='secondary'
            >
              Volunteering
            </v-chip>
          </template>
          <template #item.actions={item}>
            <v-icon
              class='mr-2 my-1 pa-2 primary white--text rounded-sm'
              dense
              @click='editItem(item)'
            >
              mdi-pencil
            </v-icon>
            <v-icon
              class='mr-2 my-1 pa-2 warning white--text rounded-sm'
              dense
              @click='suspendMember(item)'
            >
              mdi-timer-off
            </v-icon>
          </template>
          <template #no-data>
            <span>There are no archived members at the moment.</span>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
    <DialogUser/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import member from '../../services/member'
import DialogUser from '../user/components/Dialog'
import Alert from '../../components/Alert'

export default {
  components: {
    DialogUser,
    Alert
  },
  data() {
    return {
      loadingSave: false,
      dialog: false,
      loading: true,
      loadingSuspended: true,
      dialogDelete: false,
      dialogDeleteConfirmation: false,
      singleDelete: true,
      singleReincorporate: true,
      dialogReincorporate: false,
      dialogSuspend: false,
      searchTimer: null,
      allMembersSearch: '',
      membersTableMeta: {},
      allMembersNumber: null,
      allSuspendedSearch: '',
      allArchivedSearch: '',
      showSuccessAlert: false,
      showSuspendAlert: false,
      showDeleteAlert: false,
      showCreateAlert: false,
      showReincorporateAlert: false,
      selected: [],
      suspendedSelected: [],
      selectedModules: [],
      allMembersTableHeaders: [
        { text: 'Company', align: 'start', value: 'name' },
        { text: 'Employees', value: 'employeesCount' },
        { text: 'Date joined', value: 'dateJoined' },
        { text: 'Admin Name', value: 'adminName', sortable: false },
        { text: 'Missed Voting Day', value: 'missed_voting_notification', sortable: false },
        { text: 'MCBA Email', value: 'disabled_email_notification_ba', sortable: false },
        { text: 'MCBA SMS', value: 'disabled_sms_notification_ba', sortable: false },
        { text: 'Hide Voter Guide', value: 'is_hidden_voter_guide', sortable: false },
        { text: 'Active Modules', value: 'modules' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      members: [],
      suspendedMembers: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        date: '',
        website: '',
        file: '',
        adminName: [],
        modules: [],
        editedModules: {},
        missed_voting_notification: false,
        is_hidden_voter_guide: false,
        disabled_email_notification_ba: false,
        disabled_sms_notification_ba: false,
        archived: false,
      },
      suspendedTableHeaders: [
        { text: 'Company', align: 'start', value: 'name' },
        { text: 'Employees', value: 'employeesCount' },
        { text: 'Date joined', value: 'dateJoined' },
        { text: 'Admin Name', value: 'adminName', sortable: false },
        { text: 'Active Modules', value: 'modules' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      defaultItem: {
        name: '',
        date: '',
        website: '',
        file: '',
        adminName: [],
        modules: [],
        editedModules: {}
      },
      selectedTab: 0,
      tabs: [
        { id: 0, title: 'Active Members' },
        { id: 1, title: 'Suspended Members' },
        { id: 2, title: 'Archived Members' }
      ]
    }
  },
  computed: {
    ...mapState({
      sections: s => s.cmsStore.sections
    }),
    formTitle() {
      return this.editedIndex === -1 ? 'New Member Company' : 'Edit Member Company'
    },
    rules() {
      return [this.editedItem.modules.length > 0 || 'At least one module should be selected']
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    allMembersSearch() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(async () => {
        this.membersTableMeta.page === 1 ? await this.getMembers() : this.membersTableMeta.page = 1
      }, 750)
    },
    allSuspendedSearch() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(async () => {
        this.membersTableMeta.page === 1 ? await this.getSuspendedMembers() : this.membersTableMeta.page = 1
      }, 750)
    },
    allArchivedSearch() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(async () => {
        this.membersTableMeta.page === 1 ? await this.getArchivedMembers() : this.membersTableMeta.page = 1
      }, 750)
    },
    selectedTab(val) {
      this.membersTableMeta.page = 1
      this.membersTableMeta.itemsPerPage = 15
      if (val === 0) {
        this.getMembers()
      } else if (val === 1) {
        this.getSuspendedMembers()
      } else if (val === 2) {
        this.getArchivedMembers()
      }
    }
  },
  methods: {
    ...mapActions({
      crud: 'crud'
    }),
    async getMembers() {
      this.loading = true
      try {
        const { page, sortBy, sortDesc, itemsPerPage } = this.membersTableMeta
        const { data, meta: { total } } = await member.getMembers({
          page,
          sort: sortBy[0],
          method: sortBy[0] ? (sortDesc[0] ? 'DESC' : 'ASC') : undefined,
          itemsPerPage: itemsPerPage === -1 ? this.allMembersNumber : itemsPerPage,
          search: this.allMembersSearch || undefined
        })
        this.members = data
        this.allMembersNumber = total
        this.members.forEach(member => {
          member.editedModules = {
            voting: member.modules.includes('voting'),
            charity: member.modules.includes('charity'),
            donations: member.modules.includes('donations'),
            volunteering: member.modules.includes('volunteering')
          }
        })
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    async getSuspendedMembers() {
      this.loadingSuspended = true
      try {
        const { page, sortBy, sortDesc, itemsPerPage } = this.membersTableMeta
        const { data, meta: { total } } = await member.getSuspendedMembers({
          page,
          sort: sortBy[0],
          method: sortBy[0] ? (sortDesc[0] ? 'DESC' : 'ASC') : undefined,
          itemsPerPage: itemsPerPage === -1 ? this.allMembersNumber : itemsPerPage,
          search: this.allSuspendedSearch || undefined
        })
        this.suspendedMembers = data
        this.allMembersNumber = total
        this.suspendedMembers.forEach(member => {
          member.editedModules = {
            voting: member.modules.includes('voting'),
            charity: member.modules.includes('charity'),
            donations: member.modules.includes('donations'),
            volunteering: member.modules.includes('volunteering')
          }
        })
      } catch (err) {
        console.log(err)
      }
      this.loadingSuspended = false
    },
    async getArchivedMembers() {
      this.loading = true
      try {
        const { page, sortBy, sortDesc, itemsPerPage } = this.membersTableMeta
        const { data, meta: { total } } = await member.getMembers({
          page,
          sort: sortBy[0],
          method: sortBy[0] ? (sortDesc[0] ? 'DESC' : 'ASC') : undefined,
          itemsPerPage: itemsPerPage === -1 ? this.allMembersNumber : itemsPerPage,
          search: this.allArchivedSearch || undefined,
          archived: true
        })
        this.members = data
        this.allMembersNumber = total
        this.members.forEach(member => {
          member.editedModules = {
            voting: member.modules.includes('voting'),
            charity: member.modules.includes('charity'),
            donations: member.modules.includes('donations'),
            volunteering: member.modules.includes('volunteering')
          }
        })
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    selectFile(file) {
      this.editedItem.file = file
    },
    hideAlertMsg() {
      window.setTimeout(() => {
        this.showSuccessAlert = false
        this.showSuspendAlert = false
        this.showDeleteAlert = false
        this.showCreateAlert = false
        this.showReincorporateAlert = false
      }, 6000)
    },
    showAlertMsg(type) {
      switch (type) {
        case 'success':
          this.showSuccessAlert = true
          break
        case 'created':
          this.showCreateAlert = true
          break
        case 'suspend':
          this.showSuspendAlert = true
          break
        case 'delete':
          this.showDeleteAlert = true
          break
        case 'reincorporate':
          this.showReincorporateAlert = true
          break
      }
      this.hideAlertMsg()
    },
    openAddMember() {
      this.dialog = true
    },
    editItem(item) {
      this.editedIndex = this.members.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    onDeleteBtnClick() {
      this.singleDelete = false
      this.dialogDelete = true
    },
    async deleteMultipleMembers() {
      await this.deleteMembers()
      this.suspendedSelected.forEach((member) => {
        const index = this.suspendedMembers.indexOf(member)
        if (index > -1) this.suspendedMembers.splice(index, 1)
      })
      this.suspendedSelected = []
    },
    deleteItem(item) {
      this.singleDelete = true
      this.editedIndex = this.suspendedMembers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
      this.suspendedSelected = []
    },
    openDeleteConfirmation() {
      this.dialogDelete = false
      this.dialogDeleteConfirmation = true
    },
    async deleteItemConfirm() {
      if (this.singleDelete) {
        this.suspendedMembers.splice(this.editedIndex, 1)
        await this.deleteMember()
      } else {
        await this.deleteMultipleMembers()
      }
      this.closeDeleteConfirmation()
      this.showAlertMsg('delete')
    },
    closeDeleteConfirmation() {
      this.dialogDeleteConfirmation = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.$refs.form.reset()
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
    },
    goToAdmin(id) {
      // this.$router.push("/users");
      this.crud({
        dialog: true,
        title: 'New Administrator',
        id: null,
        data: {
          employee_id: id
        }
      })
    },
    onReincorporateBtnClick() {
      this.singleReincorporate = false
      this.dialogReincorporate = true
    },
    reincorporateMember(item) {
      this.singleReincorporate = true
      this.editedIndex = this.suspendedMembers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogReincorporate = true
      this.suspendedSelected = []
    },
    async reincorporateMultipleMembers() {
      await this.enableDisableMembers()
      this.suspendedSelected.forEach((member) => {
        const index = this.suspendedMembers.indexOf(member)
        if (index > -1) {
          this.suspendedMembers.splice(index, 1)
          this.members.push(index, 1)
        }
      })
      this.suspendedSelected = []
    },
    async reincorporateMemberConfirm() {
      if (this.singleReincorporate) {
        this.suspendedMembers.splice(this.editedItem, 1)
        this.members.push(this.editedItem)
        await this.enableDisableMember()
        await this.getSuspendedMembers()
      } else {
        await this.reincorporateMultipleMembers()
      }
      this.suspendedSelected = []
      this.closeReincorporate()
      this.showAlertMsg('reincorporate')
    },
    closeReincorporate() {
      this.dialogReincorporate = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    suspendMember(item) {
      this.editedIndex = this.members.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogSuspend = true
    },
    async suspendMemberConfirm() {
      this.suspendedMembers.push(this.editedItem)
      this.members.splice(this.editedIndex, 1)
      await this.enableDisableMember()
      this.selected = []
      this.closeSuspend()
      this.showAlertMsg('suspend')
    },
    closeSuspend() {
      this.dialogSuspend = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async save() {
      this.loadingSave = true
      if (this.editedIndex > -1) {
        Object.assign(this.members[this.editedIndex], this.editedItem)
        await this.editMember()
        this.close()
        this.showAlertMsg('success')
      } else {
        await this.addMember()
        this.close()
        this.showAlertMsg('created')
      }
    },
    async addMember() {
      try {
        const data = {
          name: this.editedItem.name,
          adminName: this.editedItem.adminName,
          modules: this.editedItem.modules,
          website: this.editedItem.website,
          file: this.editedItem.file,
          missed_voting_notification: this.editedItem.missed_voting_notification,
          disabled_email_notification_ba: this.editedItem.disabled_email_notification_ba,
          disabled_sms_notification_ba: this.editedItem.disabled_sms_notification_ba,
          is_hidden_voter_guide: this.editedItem.is_hidden_voter_guide,
          archived: this.editedItem.archived,
        }
        await member.addMember(data)
        if (this.selectedTab === 0) {
          await this.getMembers()
        } else if (this.selectedTab === 2) {
          await this.getArchivedMembers()
        }
      } catch (e) {
        this.loadingSave = false
      }
      this.loadingSave = false
    },
    async uploadFile() {
      try {
        await member.uploadFile({
          id: this.editedItem.id,
          file: this.editedItem.file
        })
      } catch (err) {
        console.log(err)
      }
    },
    async editMember() {
      try {
        const data = {
          id: this.editedItem.id,
          name: this.editedItem.name,
          adminName: this.editedItem.adminName,
          modules: this.editedItem.modules,
          website: this.editedItem.website,
          file: this.editedItem.file,
          missed_voting_notification: this.editedItem.missed_voting_notification,
          disabled_email_notification_ba: this.editedItem.disabled_email_notification_ba,
          disabled_sms_notification_ba: this.editedItem.disabled_sms_notification_ba,
          is_hidden_voter_guide: this.editedItem.is_hidden_voter_guide,
          archived: this.editedItem.archived,
        }
        await member.editMember(data)
        await this.getMembers()
      } catch (e) {
        this.loadingSave = false
      }
      this.loadingSave = false
    },
    async enableDisableMember() {
      try {
        await member.enableDisableMember({
          id: this.editedItem.id
        })
        if (this.selectedTab === 0) {
          await this.getMembers()
        } else if (this.selectedTab === 2) {
          await this.getArchivedMembers()
        }
      } catch (err) {
        console.log(err)
      }
    },
    async enableDisableMembers() {
      try {
        await member.enableDisableMembers({
          members: this.suspendedSelected
        })
        await this.getMembers()
      } catch (err) {
        console.log(err)
      }
    },
    async updateModules() {
      this.selected.map((selected) => {
        selected.modules = this.selectedModules
      })
      await this.editModules()
    },
    async editModules() {
      try {
        await member.editModules({
          members: this.selected,
          modules: this.selectedModules
        })
        this.selected = []
        await this.getMembers()
      } catch (err) {
        console.log(err)
      }
    },
    async deleteMember() {
      try {
        await member.deleteMember({
          id: this.editedItem.id
        })
        await this.getSuspendedMembers()
      } catch (err) {
        console.log(err)
      }
    },
    async deleteMembers() {
      try {
        await member.deleteMembers({
          members: this.suspendedSelected
        })
        await this.getSuspendedMembers()
      } catch (err) {
        console.log(err)
      }
    },
    async download() {
      try {
        await member.download()
      } catch (err) {
        // empty
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  background: white;
  padding-bottom: 12px;
  border-bottom: 1px solid #5b8dd7;
}
</style>
